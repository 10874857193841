// tagsInput.vue
<template>
  <div class="muli-tags" @click="focus">
    <button
      class="btn"
      v-for="(tag, index) in tags"
      :key="index"
      @click="delTag(index)"
    >
      {{ tag }} <span>x</span>
    </button>
    <input
      type="text"
      ref="input"
      placeholder="请输入关键词，按回车分割"
      @keydown.13="split"
      @keydown.delete="del"
      @keydown.188="split"
      v-model="current"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    // required: true,
    type: [String, Number],
    default: () => []
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    split(e) {
      e.preventDefault();
      this.add(e);
    },
    add(e) {
      const val = e.target.value;
      if (!val) return;
      if (this.tags.indexOf(val) > -1 || this.tags.length >= 30) return;
      this.tags.push(val);
      this.$emit("input", this.tags);
      this.current = "";
    },
    del(e) {
      if (!e.target.value.length) {
        this.tags.pop();
        this.$emit("input", this.tags);
      }
    },
    delTag(index) {
      this.tags.splice(index, 1);
      this.$emit("input", this.tags);
    }
  },
  computed: {
    tags() {
      return this.value.slice();
    }
  },
  data() {
    return {
      current: ""
    };
  }
};
</script>

<style lang="scss">
.muli-tags {
  text-align: left;
  background: #eeeeee;
  padding: 20px 20px;
  width: 100%;
  margin-bottom: 50px;
  height: 143px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  input {
    background: transparent;
    border: none;
    outline: none;
    height: 38px;
    width: 250px;
    line-height: 38px;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #787878;
  }
  .btn {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    // box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2500);
    display: block;
    float: left;
    padding: 0 7px;
    text-decoration: none;
    background: #ffffff;
    margin-right: 20px;
    margin-bottom: 20px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #787878;
    font-size: 17px;
    height: 38px;
    line-height: 38px;
    box-sizing: border-box;
    span {
      color: #787878;
      text-decoration: none;
      font-size: 12px;
      // width: 10px;
      // height: 10px;
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>
