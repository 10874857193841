<template>
  <div class="upload-page-video bg-light">
    <div class="upload-page-content">
      <div class="upload-up">
        <el-upload
          drag
          action=""
          accept=".mp4,.mp3,.wmv,.mpg,.mov,.avi,.ogg,.rm,.ram,.swf"
          :show-file-list="false"
          :before-upload="handleBeforeUpload"
          :http-request="customUploadVideo"
        >
          <div
            class="icon-box"
            :class="{ 'icon-box-video': ruleForm.resource_path }"
          >
            <video
              v-if="ruleForm.resource_path"
              :src="ruleForm.resource_path"
              class="video"
              controls
            >
              您的浏览器不支持 video 标签。
            </video>
            <img
              v-if="!ruleForm.resource_path"
              class="icon-upload"
              src="../../assets/images/upload-icon.png"
              alt=""
            />
          </div>
          <div class="red" v-if="loading">视频上传中，请稍后～</div>
          <div class="el-upload__texts" v-if="!ruleForm.resource_path">
            选择视频
          </div>
          <div class="el-upload__texts" v-if="!ruleForm.resource_path">
            或将视频拖放于此处
          </div>
          <div class="el-upload__btn" v-if="ruleForm.resource_path">
            重新上传
          </div>
          <div
            class="el-upload__tips"
            :class="{ 'el-upload__tips_ssp': ruleForm.resource_path }"
          >
            <p class="el-upload__tips_format">
              单条视频不大于5G，时长大于5秒，小于30分钟
            </p>
            <p>
              中国国家地理·频道为原创展示平台，请上传本人享有著作权的作品。
            </p>
            <!-- <p>禁止上传他人作品，如有侵权。。。。。。</p> -->
          </div>
        </el-upload>
      </div>
      <div class="upload-center">
        <div class="upload-form">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="115px"
            class="ruleForm"
          >
            <el-form-item prop="title" label-width="0px">
              <span class="label-up">作品名称</span>
              <el-input
                class="input-must"
                style="width: 485px;margin-bottom:70px;height: 54px;line-height:54px;display:block;background: #EEEEEE;border: 0px solid #10070B;border-radius: 7px;"
                v-model="ruleForm.title"
                placeholder="请输入作品名称"
              ></el-input>
            </el-form-item>
            <el-form-item
              label-width="0px"
              prop="resource_fimage"
              v-if="ruleForm.resource_type == 2"
            >
              <span class="label-up">封面</span>
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                multiple
                :http-request="customUploadPoster"
                style="margin-bottom:72px;display:flex;"
              >
                <el-image
                  v-if="ruleForm.resource_fimage"
                  :src="ruleForm.resource_fimage"
                  fit="cover"
                  class="avatar"
                >
                </el-image>
                <img
                  v-else
                  class="icon-upload"
                  src="../../assets/images/upload-icon.png"
                  alt=""
                />

                <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                <div class="el-upload__tip" slot="tip">
                  <div class="up  poster-must">*</div>
                  <div class="down">
                    <span>图片比例 16:9</span>
                    <span>格式：jpeg、png ，文件≤5MB</span>
                    <span
                      >若无可使用的封面，系统将自动截取视频画面作为封面使用</span
                    >
                  </div>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item prop="description" label-width="0px">
              <span class="label-up">作品简介</span>
              <el-input
                style="margin-bottom:70px;"
                type="textarea"
                resize="none"
                v-model="ruleForm.description"
                placeholder="每一个作品背后有专属于它自己的故事"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-row style="margin-bottom:52px;">
              <el-col :span="15">
                <el-form-item label="拍摄时间" prop="shooting_time">
                  <el-date-picker
                    type="date"
                    placeholder="年-月-日"
                    v-model="ruleForm.shooting_time"
                    value-format="yyyy-MM-dd"
                    style="width: 253px;height: 54px;line-height:54px;background: #EEEEEE;border: 0px solid #10070B;border-radius: 7px;"
                  ></el-date-picker> </el-form-item
              ></el-col>
              <el-col :span="9"
                ><el-form-item label="拍摄设备" prop="shooping_equipment">
                  <el-input
                    style="width: 253px;height: 54px;line-height:54px;background: #EEEEEE;border: 0px solid #10070B;border-radius: 7px;"
                    v-model="ruleForm.shooping_equipment"
                    placeholder="请输入拍摄设备"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row style="margin-bottom:56px;">
              <el-col :span="15">
                <el-form-item label="拍摄地点" prop="shooting_address">
                  <el-input
                    style="width: 423px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;"
                    v-model="ruleForm.shooting_address"
                    placeholder="请输入拍摄地址"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="属性" prop="attribute">
                  <el-select
                    class="input-must"
                    style="width: 109px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;"
                    :popper-append-to-body="false"
                    v-model="ruleForm.attribute"
                    placeholder="请选择作品属性"
                  >
                    <el-option label="公开" :value="1"></el-option>
                    <el-option label="私密" :value="2"></el-option>
                  </el-select> </el-form-item
              ></el-col>
            </el-row>
            <el-row style="margin-bottom:60px;">
              <el-col :span="15">
                <el-form-item label="拍摄方式" prop="shooting_model">
                  <el-select
                    class="input-must"
                    style="width: 127px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;"
                    :popper-append-to-body="false"
                    v-model="ruleForm.shooting_model"
                    placeholder="-请选择-"
                  >
                    <el-option
                      v-for="item in shootingWayArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="拍摄速度" prop="shooping_speed">
                  <el-select
                    style="width: 127px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;"
                    :popper-append-to-body="false"
                    v-model="ruleForm.shooping_speed"
                    placeholder="-请选择-"
                  >
                    <el-option
                      v-for="item in shootingSpeedArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item prop="keywords" label-width="0px">
              <div class="tips-box">
                <span class="label-up">关键词</span>
                <img
                  style="width:14px;height:14px;margin-left:22px;"
                  src="@/assets/images/keywords-icon.png"
                  alt=""
                />
                <div class="tips">{{ count }}/30个关键词</div>
              </div>
              <tags-input v-model="tags"> </tags-input>
              <!-- <span class="label-up">关键词</span>
              <img
                style="width:14px;height:14px;margin-left:22px;"
                src="@/assets/images/keywords-icon.png"
                alt=""
              /> -->
              <!-- <el-input
                type="textarea"
                resize="none"
                maxlength="100"
                placeholder="请输入关键词，多个以、隔开"
                show-word-limit
                v-model="ruleForm.keywords"
                style="margin-bottom:16px;"
              ></el-input> -->
            </el-form-item>
            <el-form-item label="分类" prop="category_name">
              <el-select
                class="input-must"
                popper-class="roleSelectVideo"
                style="width: 109px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;margin-bottom:40px;"
                :popper-append-to-body="false"
                v-model="ruleForm.category_name"
                placeholder="未分类"
              >
                <el-option
                  v-for="item in classifyArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="水印" prop="watermark">
              <el-radio-group v-model="ruleForm.watermark">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
              <span class="water-mark-tips radio-must"
                >（给作品添加中国国家地理·频道水印）</span
              >
            </el-form-item>
            <!-- <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确认上传</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item> -->
          </el-form>
        </div>
      </div>
      <div class="upload-down">
        <div class="submit" @click="submitForm('ruleForm')">确认上传</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideoDuration } from "@/utils/utils.js";
import TagsInput from "@/components/tagsInput/tagsInput";
// 要求上传的文件后缀
// const fileSuffix = ["mp4", "mp3"];
export default {
  name: "uploadProductionVideo",
  components: {
    TagsInput
  },
  data() {
    return {
      loading: false,
      shootingWayArr: [
        { label: "地面", value: 0 },
        { label: "航拍", value: 1 },
        { label: "水下", value: 2 },
        { label: "微距", value: 3 },
        { label: "延时", value: 4 }
      ],
      shootingSpeedArr: [
        { label: "正常", value: 0 },
        { label: "延时", value: 1 },
        { label: "慢动作", value: 2 }
      ],
      classifyArr: [
        {
          label: "动物",
          value: "1"
        },
        {
          label: "植物",
          value: "2"
        },
        {
          label: "自然",
          value: "3"
        },
        {
          label: "人文",
          value: "4"
        },
        {
          label: "天文",
          value: "5"
        },
        {
          label: "水下",
          value: "6"
        },
        {
          label: "运动",
          value: "7"
        },
        {
          label: "纪录片",
          value: "8"
        },
        {
          label: "Vlog",
          value: "9"
        },
        {
          label: "科技",
          value: "10"
        }
        // { label: "动物", value: 0 },
        // { label: "植物", value: 1 },
        // { label: "自然", value: 2 },
        // { label: "人文", value: 3 },
        // { label: "城市", value: 4 },
        // { label: "水下", value: 5 },
        // { label: "微距", value: 6 },
        // { label: "航拍", value: 7 },
        // { label: "创意", value: 8 }
      ],
      tags: [], //关键词
      ruleForm: {
        user_id: localStorage.getItem("user_id"), //用户id
        resource_from: 1, //1前台添加2后台添加
        resource_type: 2, //1图片 2 视频
        resource_path: null, // 地址
        shot_video_path: null, //裁剪视频地址
        water_resource_path: null, //水印地址
        yasou_resource_path: null, //压缩地址
        duration: "", //视频时长
        title: "",
        attribute: 1,
        shooping_equipment: "",
        shooting_time: "",
        shooting_address: "", //拍摄地点
        shooting_model: "",
        shooping_speed: "",
        category_name: "",
        keywords: "",
        watermark: 1,
        description: "",
        resource_fimage: ""
      },
      rules: {
        // title: [
        //   { required: true, message: "请输入作品名称", trigger: "blur" },
        //   { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        // ],
        // shooping_equipment: [
        //   { required: true, message: "请输入拍摄设备", trigger: "blur" }
        // ],
        // shooting_model: [
        //   { required: true, message: "请选择", trigger: "change" }
        // ],
        // shooping_speed: [
        //   { required: true, message: "请选择", trigger: "change" }
        // ],
        // category_name: [
        //   { required: true, message: "请选择", trigger: "change" }
        // ],
        // attribute: [
        //   { required: true, message: "请选择属性", trigger: "change" }
        // ],
        // shooting_address: [
        //   { required: true, message: "请输入拍摄地点", trigger: "blur" }
        // ],
        // shooting_time: [
        //   {
        //     type: "string",
        //     required: true,
        //     message: "请选择日期",
        //     trigger: "change"
        //   }
        // ],
        // watermark: [
        //   { required: true, message: "请选择是否添加水印", trigger: "change" }
        // ],
        // description: [
        //   { required: true, message: "请添加描述", trigger: "blur" }
        // ],
        // keywords: [
        //   { required: true, message: "请输入关键词", trigger: "blur" }
        // ],
        // resource_path: [
        //   { required: true },
        //   {
        //     validator: (rule, value, callback) =>
        //       this.checkUploadImg(rule, value, callback)
        //   }
        // ]
      }
    };
  },
  created() {},
  computed: {
    //count 关键词当前已有数量
    count() {
      return this.tags.length;
    }
  },
  async mounted() {},
  methods: {
    submitForm(formName) {
      let that = this;
      that.ruleForm.keywords = this.tags.join("、");
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 调用上传作品接口
          this.$api.postUpProduction(this.ruleForm).then(res => {
            if (res.data.code === 1) {
              console.log(res);
              this.$message({
                message: res.data.msg,
                type: "success"
              });
              setTimeout(() => {
                that.$router.push({
                  name: "personalCenter",
                  query: { PageUserId: localStorage.getItem("user_id") }
                });
              }, 3000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 上传视频前调用
     * @param {object} file 文件对象
     * @returns {void}
     */
    handleBeforeUpload(file) {
      const self = this;
      console.log(file);
      // const type = fileType(file.name);
      // if (!fileSuffix.includes(type)) {
      //   this.$message.error("目前仅支持上传mp4格式的视频文件");
      //   this.loading = false;
      //   return false;
      // }
      if (file.size > 5 * 1024 * 1024 * 1024) {
        // this.$message.error("仅支持单条视频不大于5G，时长大于5秒，小于30分钟");
        this.$message({
          message: "仅支持单条视频不大于5G，时长大于5秒，小于30分钟",
          type: "error",
          customClass: "error_self",
          center: true
        });
        this.loading = false;
        return false;
      }
      const isSize = new Promise(function(resolve) {
        let _URL = window.URL || window.webkitURL;
        let videoElement = document.createElement("video");
        // 当指定的音频/视频的元数据已加载时，会发生 loadedmetadata 事件。 元数据包括：时长、尺寸（仅视频）以及文本轨道。
        videoElement.addEventListener("loadedmetadata", function() {
          let duration = videoElement.duration; // 视频时长
          if (Math.floor(duration) < 5 || Math.floor(duration) >= 30 * 60) {
            this.loading = false;
            return self.$message({
              message: "仅支持单条视频不大于5G，时长大于5秒，小于30分钟",
              type: "error",
              customClass: "error_self",
              center: true
            });
          }
          resolve();
        });
        videoElement.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          self.$message.error("请重新上传！");
          return Promise.reject();
        }
      );
      return isSize;

      // const that = this;
      // console.log(9999999, file);
      // if (file.type !== "video/mp4") {
      //   this.$message.error("目前仅支持上传mp4格式的视频文件");
      //   this.loading = false;
      //   return false;
      // }
      // return new Promise(function(resolve, reject) {
      //   const url = URL.createObjectURL(file);
      //   const video = document.createElement("video");
      //   video.onloadedmetadata = evt => {
      //     let eo = null;
      //     try {
      //       eo = evt.srcElement || evt.path[0];
      //     } catch (error) {
      //       console.log(error);
      //     }
      //     if (!eo) {
      //       that.$message.error("获取视频对象出错,请联系管理员");
      //       return;
      //     }
      //     // 总秒数
      //     let duration = Math.floor(eo.duration);
      //     console.log(88888888, duration);
      //     // const duration = Math.floor(evt.path[0].duration)
      //     if (duration < 3 || duration > 180) {
      //       that.uploaderInfo.uploadText = "";
      //       that.uploaderInfo.isVideoUploadSuccess = true;
      //       let message = "上传视频限制在3-180s";
      //       that.$message.error(message);
      //       reject(new Error(message));
      //       return;
      //     }
      //   };
      //   video.src = url;
      //   video.load();
      // });
    },
    customUploadVideo(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      this.loading = true;
      this.$api
        .postUpVideo(formData)
        .then(res => {
          if (res.data.code === 1) {
            this.ruleForm.resource_path = res.data.data.video_url;
            this.ruleForm.resource_fimage = res.data.data.resource_fimage;
            this.ruleForm.shot_video_path = res.data.data.shot_video_path;
            this.$refs.ruleForm.validateField("resource_path", valid => {
              if (!valid) return false;
            });
            getVideoDuration(res.data.data.video_url).then(res => {
              this.ruleForm.duration = res;
            });
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        })
        .catch(res => {
          console.log(123, res);
        });
    },
    //视频封面图
    customUploadPoster(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      this.$api.postUpImages(formData).then(res => {
        if (res.data.code === 1) {
          this.ruleForm.resource_fimage = res.data.data.yuantu;
          this.ruleForm.yasou_resource_path = res.data.data.yasuo_url;
          this.ruleForm.water_resource_path = res.data.data.water_url;
          this.$refs.ruleForm.validateField("resource_fimage", valid => {
            if (!valid) return false;
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**
     * 检查是否上传资源
     */
    checkUploadImg(rule, value, callback) {
      if (!value) {
        callback(new Error("请上传资源"));
        return;
      }
      callback();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.resource_fimage = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.roleSelectVideo {
  height: 463px;
  .el-scrollbar {
    height: 100%;
    .el-select-dropdown__wrap {
      max-height: 470px;
    }
  }
}
.error_self {
  top: 110px !important;
  background: #e60021 !important;
}

.error_self .el-message__content {
  color: #ffffff !important;
  font-family: PingFang SC !important;
  font-weight: 400 !important;
}
.upload-page-video {
  color: #000000;
  text-align: center;
  .upload-page-content {
    width: 1312px;
    margin: 0 auto;
    padding-top: 54px;
  }
  .upload-up {
    width: 100%;
    height: 645px;
    box-shadow: 0px 26px 17px 0px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    margin-bottom: 47px;
    text-align: center;
    .el-upload {
      width: 100%;
      height: 645px;
      .el-upload-dragger {
        background-color: #fff;
        border-radius: 6px;
        box-sizing: border-box;
        border: none;
        width: 100%;
        height: 100%;
        text-align: center;
        position: relative;
        overflow: hidden;
        .icon-box {
          height: 191px;
          width: 191px;
          margin: 146px auto 54px;
          img,
          video {
            height: 100%;
            width: 100%;
          }
        }
        .icon-box-video {
          height: 372px;
          width: 1000px;
          margin: 54px auto 54px;
          video {
            height: 100%;
            width: 100%;
          }
        }
        .red {
          position: relative;
          top: -20px;
          color: red;
        }
        .el-upload__texts {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #6a6a6a;
          line-height: 40px;
        }
        .el-upload__btn {
          width: 97px;
          height: 23px;
          background: #e60021;
          border-radius: 12px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 23px;
          display: inline-block;
        }
      }
    }
    .el-upload__tips {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #9d9d9d;
      line-height: 18px;
      background: #fff;
      padding-top: 68px;
      .el-upload__tips_format {
        margin-bottom: 18px;
      }
    }
    .el-upload__tips_ssp {
      padding-top: 25px;
    }
  }
  .upload-center {
    width: 100%;
    height: 1513px;
    background: #ffffff;
    box-shadow: 0px 26px 17px 0px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    text-align: center;
    .upload-form {
      padding: 105px 166px 75px;
      .el-form {
        .el-form-item {
          text-align: left;
          margin-bottom: 0;
          .el-form-item__label {
            text-align: left;
            height: 54px;
            line-height: 54px;
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .el-radio__input.is-checked .el-radio__inner {
            border-color: #e6767b;
            background: #e6767b;
          }
          .el-radio__input.is-checked + .el-radio__label {
            color: #e6767b;
          }
          .el-radio__label {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #000000;
          }

          .el-radio__inner {
            width: 21px;
            height: 21px;
          }
          .el-textarea__inner {
            width: 100%;
            display: block;
            height: 143px;
            font-size: 17px;
            font-family: PingFangSC-Light;
            font-weight: 300;
            color: #000000;
            background: #eeeeee;
            border: 0px solid #10070b;
            border-radius: 7px;
            &::placeholder {
              color: #787878;
            }
            &::-webkit-input-placeholder {
              /* WebKit browsers 适配谷歌 */
              color: #787878;
            }
            &:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 适配火狐 */
              color: #787878;
            }
            &::-moz-placeholder {
              /* Mozilla Firefox 19+ 适配火狐 */
              color: #787878;
            }
            &:-ms-input-placeholder {
              /* Internet Explorer 10+  适配ie*/
              color: #787878;
            }
          }
          .input-must {
            position: relative;
          }
          .input-must:after {
            font-family: monospace;
            position: absolute;
            top: 2px;
            right: -32px;
            content: "*";
            font-size: 22px;
            color: #f56c6c;
          }
          .radio-must {
            position: relative;
          }
          .radio-must:after {
            font-family: monospace;
            position: absolute;
            right: -20px;
            top: -6px;
            content: "*";
            font-size: 22px;
            color: #f56c6c;
          }
          .label-up {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .water-mark-tips {
            padding-left: 60px;
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #787878;
          }
          .avatar-uploader .el-upload {
            width: 219px;
            height: 123px;
            border-radius: 7px;
            background: #eeeeee;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }
          // .avatar-uploader .avatar {
          //   width: 219px;
          //   height: 123px;
          //   border-radius: 7px;
          // }
          .avatar-uploader .el-upload:hover {
            border-color: #409eff;
          }
          .icon-upload {
            margin-top: 28px;
            width: 67px;
            height: 67px;
          }
          .avatar-uploader .avatar {
            width: 100%;
            // height: 178px;
            display: block;
          }
          .avatar-uploader .el-upload__tip {
            padding-left: 15px;
            display: flex;
            height: 123px;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: 0;
          }
          .poster-must {
            font-family: monospace;
            font-size: 22px;
            color: #f56c6c;
          }
          .avatar-uploader .el-upload__tip .down {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-end;
          }
          .avatar-uploader .el-upload__tip .down span {
            line-height: normal;
          }
        }
      }
      .el-input__inner {
        border: none;
        background: #eeeeee;
        border-radius: 7px;
        font-size: 17px;
        font-family: PingFangSC-Light;
        font-weight: 300;
        color: #000000;
        &::placeholder {
          color: #787878;
        }
        &::-webkit-input-placeholder {
          /* WebKit browsers 适配谷歌 */
          color: #787878;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 适配火狐 */
          color: #787878;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ 适配火狐 */
          color: #787878;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10+  适配ie*/
          color: #787878;
        }
      }
      .el-textarea__inner {
        background: #eeeeee;
      }
      .el-input__count {
        background: #eeeeee;
      }
      .tips-box {
        position: relative;
        .tips {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #787878;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
  .upload-down {
    width: 100%;
    padding: 62px 0;
    text-align: center;
    .submit {
      margin: 0 auto;
      height: 38px;
      line-height: 38px;
      width: 322px;
      text-align: center;
      border-radius: 30px;
      background-color: #ba001b;
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
// 媒体查询 小于等于某个宽度
@media screen and (max-width: 1339px) {
  .upload-page-video {
    .upload-page-content {
      width: 1200px;
    }
  }
}
@media screen and (min-width: 1340px) and (max-width: 1921px) {
  .upload-page-video {
    .upload-page-content {
      width: 1312px;
    }
  }
}
@media screen and (min-width: 1921px) {
  .upload-page-video {
    .upload-page-content {
      width: 1312px;
    }
  }
}
</style>
<style lang="scss" scoped>
//重置select样式
/* 修改dropdown里面的背景颜色和边框颜色 */
::v-deep .el-select-dropdown {
  width: 109px;
  background-color: #acacac !important;
}

/* 修改dropdown里面的字体颜色 */
.el-select-dropdown__item {
  font-size: 17px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding: 0;
  margin: 0 26px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  &:last-child {
    border-bottom: none;
  }
}
/* 修改选择option：hover的颜色 */
.el-select-dropdown__item:hover {
  background-color: none;
}
/* 修改选择option后的颜色 */
.el-select-dropdown__item.selected {
  background-color: #acacac;
}
/* 修改滑入滑出背景样式 */
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #acacac;
}
/* 修改最上面小三角颜色 */
::v-deep .popper__arrow {
  display: none !important;
}
.el-select-dropdown
  .el-select-dropdown__wrap
  .el-select-dropdown__item.selected {
  color: #ffffff;
}
</style>
